<template>
        <q-card style="width: 600px; max-width: 60vw">
            <q-bar class="bg-primary text-white">
                <q-icon name="fas fa-keyboard" />
                <div>
                    
                    <small class="q-ml-sm text-size-sm">
                        {{ PdmMaPatientinfoEntity.PdmMaPatientinfoName }}({{
                            PdmMaPatientinfoEntity.PdmMaPatientinfoID
                        }})
                    </small>
                </div>

                <q-space />

                <q-btn dense flat icon="close" v-close-popup>
                    <q-tooltip>关闭</q-tooltip>
                </q-btn>
            </q-bar>

            <q-card-section class="q-pa-sm">
                <div class="row q-gutter-sm q-mb-sm">
                    <q-input
                        label="机构ID" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.HospID "
                    />
                    <q-input
                        label="His患者ID" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.His_PatientID "
                    />
                    <q-input
                        label="患者名称" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.Name "
                    />
                    <q-input
                        label="身份证号" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.CertNo "
                    />
                    <q-input
                        label="联系电话" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.TelPhone "
                    />
                    <q-input
                        label="添加日期" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmMaPatientinfoEntity.CDate "
                    />
                </div>
            </q-card-section>

            <q-card-actions align="right" class="bg-white text-teal">
                <q-btn flat label="取消" v-close-popup />
                <q-btn flat label="确定" @click="onOK" />
            </q-card-actions>
        </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    components: {},
    props: {},
    computed: {
        ...mapState("PdmMaPatientinfo", [
            "PdmMaPatientinfoShowDlg",
            "PdmMaPatientinfoEntity",
            "PdmMaPatientinfoList",
        ]),
     
     
     
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        ...mapActions("PdmMaPatientinfo", ["actUpdatePdmMaPatientinfo", "actGetPdmMaPatientinfoList"]),
        onOK() {
         this.actUpdatePdmMaPatientinfo({
                        HospID: this.$store.getters["appUser/hospID"],
                        CUser: this.$store.getters["appUser/userID"],
                        Info: this.PdmMaPatientinfoEntity,
                    }).then((res) => {
                        if (res.Code == 0) {
                           this.$emit("onLoadData");
                           this.msg.success(res.Message);
                        }else{
                           this.msg.error(res.Message);
                        }
                      });
        },
    },
};
</script>
