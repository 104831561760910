<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="编码\名称"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmMaPatientinfoListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmMaPatientinfoListLoading" @click="onExportData">导出</q-btn> -->
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmMaPatientinfoListLoading"
          @click="onAdd"
          >新增</q-btn
        >
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmMaPatientinfoListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            @click="onEdit(row)"
            >视频上传</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmMaPatientinfoshowDlg" persistent>
    <MaPatientinfoEdit @onLoadData="onLoadData" />
  </q-dialog>
  <q-dialog v-model="maPathShowDlg" persistent>
    <MaPathView  />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import MaPatientinfoEdit from "./maPatientinfoEdit.vue";
import MaPathView from "./maPathView.vue";

export default {
  components: {
    MaPatientinfoEdit,
    MaPathView
  },
  computed: {
    ...mapState("PdmMaPatientinfo", [
      "PdmMaPatientinfoList",
      "PdmMaPatientinfoListLoading",
      "PdmMaPatientinfoEntity",
      "PdmMaPatientinfoShowDlg",
      "MaPathShowDlg"
    ]),

    pdmMaPatientinfoshowDlg: {
      get() {
        return this.PdmMaPatientinfoShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaPatientinfo/setPdmMaPatientinfoShowDlg", val);
      },
    },
    maPathShowDlg: {
      get() {
        return this.MaPathShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaPatientinfo/setMaPathShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          // {
          //   field: "HospID",
          //   title: "机构ID",
          //   sortable: true,
          //   align: "center",
          //   width: 160,
          // },
          {
            field: "His_PatientID",
            title: "His患者ID",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "Name",
            title: "患者名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "CertNo",
            title: "身份证号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "TelPhone",
            title: "联系电话",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "CDate",
            title: "添加日期",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmMaPatientinfoList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaPatientinfo", [
      "actGetPdmMaPatientinfoListByPage",
      "actCreatePdmMaPatientinfo",
      "actEditPdmMaPatientinfoData",
      "actEditPdmMaPatientinfo",
      "actDeletePdmMaPatientinfo",
    ]),
    onLoadData() {
      this.actGetPdmMaPatientinfoListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit(
            "PdmMaPatientinfo/setPdmMaPatientinfoListLoading",
            false
          );
          //this.PdmMaPatientinfoListLoading = false;
        });
    },
    onAdd() {
      this.actCreatePdmMaPatientinfo({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(row) {
      this.$store.commit("PdmMaPatientinfo/setPdmMaPatientinfoEntity", row);
      this.$store.commit("PdmMaPatientinfo/setMaPathShowDlg", true);
    },
    onDelete(e) {
      this.actDeletePdmMaPatientinfo({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
