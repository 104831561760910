<template>
  <q-card style="width: 700px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          视频上传
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <!-- <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="编码\名称"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmMaPathListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn> -->
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmMaPathListLoading" @click="onExportData">导出</q-btn> -->
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmMaPathListLoading"
          @click="onAddImg"
          >上传图片</q-btn
        >
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmMaPathListLoading"
          @click="onAddMp4"
          >上传视频</q-btn
        >
        <q-uploader
          :url="Api_Url + 'api/PDM_File/UploadFile_MA'"
          multiple
          style="max-width: 300px"
          ref="Files"
          v-show="false"
          auto-upload
          accept=".jpg, image/*"
          @uploaded="onUploaded"
          @uploading="onUploading"
          :headers="[
            { name: 'HospID', value: HospID },
            { name: 'PatientNO', value: PatientNO },
            { name: 'fileDirectoryName', value: 'MA' },
            { name: 'Types', value: 'image' },
          ]"
        />
        <q-uploader
          :url="Api_Url + 'api/PDM_File/UploadFile_MA'"
          multiple
          style="max-width: 300px"
          ref="Mp4Files"
          v-show="false"
          auto-upload
          accept=".mp4"
          @uploaded="onUploaded"
          @uploading="onUploading"
          :headers="[
            { name: 'HospID', value: HospID },
            { name: 'PatientNO', value: PatientNO },
            { name: 'fileDirectoryName', value: 'MA' },
            { name: 'Types', value: 'video' },
          ]"
        />
        
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 200"
        :loading="PdmMaPathListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <!-- <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          > -->
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="关闭" v-close-popup />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";

export default {
  components: {},
  computed: {
    ...mapState("PdmMaPath", [
      "PdmMaPathList",
      "PdmMaPathListLoading",
      "PdmMaPathEntity",
      "PdmMaPathShowDlg",
    ]),
    ...mapState("PdmMaPatientinfo", ["PdmMaPatientinfoEntity"]),

    pdmMaPathshowDlg: {
      get() {
        return this.PdmMaPathShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaPath/setPdmMaPathShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      Api_Url: "",
      HospID: "",
      PatientNO: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        // toolbarConfig: {
        //   perfect: true,
        //   print: true,
        //   zoom: true,
        //   custom: true,
        //   export: true,
        //   slots: {
        //     // 自定义工具栏模板
        //     buttons: "toolbar_buttons",
        //   },
        // },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "MA_Name",
            title: "文件名称",
            sortable: true,
            align: "left",
            width: 300,
          },
          {
            field: "CDate",
            title: "添加时间",
            sortable: true,
            align: "center",
            width: 140,
          },
          {
            title: "操作",
            width: 100,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmMaPathList,
        data: null,
      },
    };
  },
  created() {
    this.Api_Url = this.$store.getters["appUser/ImagesUrl"];
    this.HospID = this.PdmMaPatientinfoEntity.HospID;
    this.PatientNO = this.PdmMaPatientinfoEntity.His_PatientID;
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaPath", [
      "actGetPdmMaPathListByPage",
      "actCreatePdmMaPath",
      "actEditPdmMaPathData",
      "actEditPdmMaPath",
      "actDeletePdmMaPath",
    ]),
    onLoadData() {
      this.actGetPdmMaPathListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PatientNO: this.PatientNO,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("PdmMaPath/setPdmMaPathListLoading", false);
          //this.PdmMaPathListLoading = false;
        });
    },
    onAddImg() {
      this.$refs.Files.pickFiles();
      this.$refs.Files.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onAddMp4() {
      this.$refs.Mp4Files.pickFiles();
      this.$refs.Mp4Files.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onUploaded(info) {
      console.log("onUploaded==>", info);
      var res = JSON.parse(info.xhr.responseText).ReturnSign;
      console.log("res==>", res);
      if (res.Code == 0) {
        this.onLoadData();
        this.msg.success(res.Message);
      } else {
        dlg.alert(res.Message);
      }
      this.$store.commit("PdmMaPath/setPdmMaPathListLoading", false);
    },
    onUploading(info) {
      console.log("onUploading==>", info);
      this.$store.commit("PdmMaPath/setPdmMaPathListLoading", true);
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmMaPath(obj);
    },
    onDelete(e) {
      this.actDeletePdmMaPath({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
